<template>
  <div class="board">
    <TheHeader></TheHeader>
    <SiteNav></SiteNav>
    <div class="px-3 lg:px-36  pt-3 bg-lines bg-cover bg-fixed bg-center">

      <NotAvailable v-if="!user.company.features.board.available" name="Board"></NotAvailable>

      <div v-else class="md:container md:mx-auto">
        <nav class="nav-onpage" id="nav">
          <router-link
            class="nav-btn"
            to="../board/langsamfahrstellen"
            active-class="active"
          >
            <font-awesome-icon icon="tachometer-alt" class="icon" />
            <p class="hidden sm:block">Langsamfahrstellen</p>
          </router-link>
          <router-link
            class="nav-btn"
            to="../board/informationen-fuer-den-fahrdienst"
            active-class="active"
          >
            <font-awesome-icon icon="info-circle" class="icon" />
            <p class="hidden sm:block">Informationen für den Fahrdienst</p>
          </router-link>
          <router-link
            class="nav-btn"
            to="../board/baustellen"
            active-class="active"
          >
            <font-awesome-icon icon="hard-hat" class="icon" />
            <p class="hidden sm:block">Baustellen</p>
          </router-link>
          <!-- <router-link
            class="nav-btn"
            to="../board/pruefer"
            active-class="active"
          >
            <font-awesome-icon icon="traffic-light" class="icon" />
            <p class="hidden sm:block">Prüfer & Signale</p>
          </router-link> -->
          <!-- <router-link
            class="nav-btn"
            to="../board/facilities"
            active-class="active"
          >
            <font-awesome-icon icon="toilet" class="icon" />
            <p class="hidden sm:block">Facilities</p>
          </router-link> -->
        </nav>
        <hr class="nav-onpage-hr" />

        <router-view></router-view>

        <hr class="space mt-12 mb-6 mx-4">

        <p class="text-center space text-sm text-gray-300"><small>
          Die Informationen im Board werden bereitgestellt und verwaltet durch
          freiweillige Nutzer, die ebenfalls in Deinem Betrieb tätig sind.<br>Das
          Board mit den dargestellten Informationen kann sich jederzeit ändern
          oder auch eingestellt werden.<br>Es ersetzt nicht die üblichen
          Informationskanäle (z.B. Aushänge, E-Mails) in Deinem Betrieb. Diese
          sollten stets vorrangig beachtet werden.<br>Der StadtbahnfahrerClub ist
          weder für die Richtigkeit noch die Aktualität verantwortlich.</small>
        </p>
      </div>
  </div>
  </div>
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import TheHeader from "@/components/TheHeader";
import NotAvailable from "@/components/NotAvailable";

import { mapGetters } from "vuex";

export default {
  name: "Board",
  components: {
    SiteNav,
    TheHeader,
    NotAvailable
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.$store.dispatch("fetchUserProfile");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>